import FiveGridLine_NotchDashedLineComponent from '@wix/thunderbolt-elements/src/components/FiveGridLine/viewer/skinComps/NotchDashedLine/NotchDashedLine.skin';


const FiveGridLine_NotchDashedLine = {
  component: FiveGridLine_NotchDashedLineComponent
};


export const components = {
  ['FiveGridLine_NotchDashedLine']: FiveGridLine_NotchDashedLine
};


// temporary export
export const version = "1.0.0"
